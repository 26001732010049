import React from 'react';

const ProfessionalProfile = ({ data }) => {
    return (
        <div className='content-center mx-auto max-w-4xl pb-6'>
            <div className='mx-10'>
                <span className='section-header'>Professional Profile</span>
                {data &&
                    data.map((pr, i) => (
                        <div className='mt-4 mb-8'>
                            <ul>
                                <li
                                    className={
                                        'list-none ' + pr
                                            ? 'pt-4 pb-2'
                                            : 'py-4'
                                    }>
                                    {pr}
                                </li>
                            </ul>
                        </div>
                    ))}
                    <div className='mt-4 mb-8'>
                        <a 
                            href="https://www.manning.com/liveproject/create-an-open-banking-app-using-openapis-and-spring-boot"
                            target='_blank'
                            rel='noreferrer'
                            className='mr-4'>
                            Author of the liveProject <i>Create an Open Banking App Using OpenAPIs and Spring Boot</i>, Manning Publishing 2020.</a>
                    </div>
            </div>
        </div>
    );
};

export default ProfessionalProfile;
